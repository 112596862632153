'use client';
import { getExternalLink } from 'core/BasicImage/basic-image.util';
import BasicLink from 'core/BasicLink';
import { useEffect, useState } from 'react';
import { useCookie } from 'react-use';

import cn from './style.module.sass';

export const isServer = () => typeof window === 'undefined';
export default function Cookies() {
    const [cookieValue, updateCookie] = useCookie('acceptedCookies');

    const [isShow, setIsShow] = useState(false);

    const setAcceptedCookie = () => {
        const cookiesLife = new Date(new Date().setMonth(new Date().getMonth() + 1));
        updateCookie('true', { expires: cookiesLife });
        setIsShow(false);
    };

    useEffect(() => {
        if (!cookieValue) {
            setIsShow(true);
        }
    }, []);

    if (isServer() || !isShow) {
        return null;
    }

    return (
        <div className={cn.cookies}>
            <div className={cn['cookies__wrapper']}>
                <div className={cn['cookies__text']}>
                    Мы&nbsp;используем куки для улучшения работы сайта. Если вы&nbsp;продолжаете
                    использовать сайт, то&nbsp;соглашаетесь с&nbsp;
                    <BasicLink
                        href={getExternalLink('/files/rules-cookie.pdf')}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        условиями использования куки
                    </BasicLink>
                    .
                </div>
                <button className={cn['cookies__btn']} type="button" onClick={setAcceptedCookie}>
                    Хорошо
                </button>
            </div>
        </div>
    );
}
