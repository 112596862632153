import clsx from 'clsx';
import BasicLink from 'core/BasicLink';
import { link } from 'shared/Header/constants';
import NavigationActive from 'shared/Header/HeaderMenu/NavigationActive';

import cn from './style.module.sass';

export default function HeaderMenu() {
    return (
        <div className={cn['header-menu']}>
            <div className={cn['header-menu__wrapper']}>
                <div className={cn['header-menu__grid']}>
                    {link.map(({ link, linkName, linkDownList, isBig }) => {
                        const className = linkDownList ? cn['header-menu__item--down'] : '';
                        const classDownBig = isBig ? cn['header-menu-down--big'] : '';

                        const childLinks = linkDownList
                            ?.filter(item => item.link)
                            .map(item => item.link);

                        return (
                            <div
                                key={linkName}
                                className={clsx(cn['header-menu__item'], className)}
                            >
                                <div className={cn['header-menu__item-wrap']}>
                                    {link ? (
                                        <BasicLink
                                            activeClassName={cn.active}
                                            href={link}
                                            className={cn['header-menu__link']}
                                        >
                                            {linkName}
                                        </BasicLink>
                                    ) : (
                                        <NavigationActive
                                            linkName={linkName}
                                            childLinks={childLinks}
                                        />
                                    )}
                                    {linkDownList && (
                                        <div className={clsx(cn['header-menu-down'], classDownBig)}>
                                            <div className={cn['header-menu-down__wrapper']}>
                                                {linkDownList?.map(
                                                    ({ link, linkName, isBlank }) => (
                                                        <div
                                                            key={linkName}
                                                            className={cn['header-menu-down__item']}
                                                        >
                                                            {isBlank ? (
                                                                <BasicLink
                                                                    href={link}
                                                                    target="_blank"
                                                                    className={clsx(
                                                                        cn[
                                                                            'header-menu-down__link'
                                                                        ],
                                                                        cn[
                                                                            'header-menu-down__link--icon'
                                                                        ]
                                                                    )}
                                                                >
                                                                    {linkName}
                                                                </BasicLink>
                                                            ) : (
                                                                <BasicLink
                                                                    href={link}
                                                                    className={
                                                                        cn['header-menu-down__link']
                                                                    }
                                                                >
                                                                    {linkName}
                                                                </BasicLink>
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
