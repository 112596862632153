'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import { getBasicPath } from '@/(home)/home.util';

export default function YandexMetrika() {
    const pathname = usePathname();

    useEffect(() => {
        const url = getBasicPath(pathname);
        // eslint-disable-next-line no-magic-numbers
        window?.ym(12895207, 'hit', url);
    }, [pathname]);

    return null;
}
