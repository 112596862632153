export const linkTop = [
    {
        link: '/service/development',
        linkName: 'Digital-интегратор',
    },
    {
        link: '/service/marketing',
        linkName: 'Интернет-маркетинг',
    },
    {
        link: '/service/outstaff',
        linkName: 'Аутстаф',
    },
];

export const linkBot = [
    {
        link: '/company',
        linkName: 'Компания',
    },
    {
        link: '/cases',
        linkName: 'Проекты',
    },
    {
        link: 'https://team.webpractik.ru/',
        linkName: 'Команда',
        isBlank: true,
    },
    {
        link: '/contacts',
        linkName: 'Контакты',
    },
];

export const link = [
    {
        link: '',
        linkName: 'Компания',
        isStatic: true,
        linkDownList: [
            {
                link: '/company',
                linkName: 'Об агентстве',
                isBlank: false,
            },
            {
                link: 'https://team.webpractik.ru/',
                isBlank: true,
                linkName: 'Команда',
            },
        ],
    },
    {
        link: '',
        linkName: 'Направления',
        isStatic: true,
        isBig: true,
        linkDownList: [
            {
                link: '/service/development',
                linkName: 'Digital-интегратор',
                isBlank: false,
            },
            {
                link: '/service/marketing',
                linkName: 'Интернет-маркетинг',
                isBlank: false,
            },
            {
                link: '/service/outstaff',
                linkName: 'Аутстаф',
                isBlank: false,
            },
        ],
    },
    {
        link: '/cases',
        linkName: 'Кейсы',
    },
    {
        link: '/contacts',
        linkName: 'Контакты',
    },
];
