export const socialList = [
    {
        href: 'https://rostov.hh.ru/employer/726289',
        src: '/images/svg/footer-hh.svg',
        alt: 'hh.ru',
        iconWidth: 26,
        iconHeight: 26,
    },
    {
        href: 'https://vk.com/agencywebpractik',
        src: '/images/svg/footer-vk.svg',
        alt: 'vk.com',
        iconWidth: 22,
        iconHeight: 13,
    },
    {
        href: 'https://t.me/webpractik_agency',
        src: '/images/svg/footer-tg.svg',
        alt: 'tg.org',
        iconWidth: 40,
        iconHeight: 40,
    },
];
