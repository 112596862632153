import BasicImage from 'core/BasicImage';
import BasicLink from 'core/BasicLink';
import { socialList } from 'shared/Footer/constants';

import cn from './style.module.sass';
export default function FooterSocial() {
    return (
        <div className={cn['footer-social']}>
            {socialList.map(item => (
                <div key={item.alt} className={cn['footer-social__item']}>
                    <BasicLink
                        href={item.href}
                        target="_blank"
                        className={cn['footer-social__link']}
                    >
                        <BasicImage
                            width={item.iconWidth}
                            height={item.iconHeight}
                            src={item.src}
                            alt={item.alt}
                        />
                    </BasicLink>
                </div>
            ))}
        </div>
    );
}
