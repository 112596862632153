import BasicImage from 'core/BasicImage';
import { getExternalLink } from 'core/BasicImage/basic-image.util';
import BasicLink from 'core/BasicLink';
import Logo from 'core/Logo';
import OfferButton from 'shared/sections/OfferButton';

import BurgerSocial from './BurgerSocial';
import MenuBurger from './MenuBurger';
import cn from './style.module.sass';

type BurgerMenuProps = {
    closeMenu: () => void;
};

export default function BurgerMenu({ closeMenu }: BurgerMenuProps) {
    const BurgerInfoRow = cn['burger-info__row'];

    return (
        <div className={cn['burger-menu']}>
            <div className={cn['burger-menu__wrapper']}>
                <div className={cn['burger-menu__burger']}>
                    <BasicLink className={cn['burger-menu__logo']} href="/">
                        <Logo />
                    </BasicLink>
                    <BasicLink
                        className={cn['header-lang']}
                        href="https://webpractik.com"
                        target="_blank"
                    >
                        EN
                    </BasicLink>
                </div>
                <div className={cn['burger-menu__grid']}>
                    <div className={cn['burger-menu__top']}>
                        <div className={cn['burger-menu__menu']}>
                            <MenuBurger closeMenu={closeMenu} />
                        </div>
                        <div className={cn['burger-menu__btn']}>
                            <OfferButton linkText="Оставить заявку" />
                        </div>
                        <div className={cn['burger-info']}>
                            <div className={BurgerInfoRow}>
                                <BasicLink
                                    href={getExternalLink('/files/PresentationWebpractikNew.pdf')}
                                    target="_blank"
                                    className={cn['burger-presentation']}
                                    rel="noopener noreferrer"
                                >
                                    <BasicImage
                                        width={22}
                                        height={22}
                                        src="/images/svg/presentation.svg"
                                        alt="Презентация агентства"
                                    />
                                    <span>Презентация агентства</span>
                                </BasicLink>
                            </div>
                            <div className={BurgerInfoRow}>
                                <BasicLink
                                    href="tel:84955405179"
                                    className={cn['burger-info__phone-link']}
                                >
                                    +7 495 540-51-79
                                </BasicLink>
                            </div>
                            <div className={BurgerInfoRow}>
                                <BasicLink
                                    href="mailto:info@webpractik.ru"
                                    className={cn['burger-info__email-link']}
                                >
                                    info@webpractik.ru
                                </BasicLink>
                            </div>
                        </div>
                    </div>
                    <div className={cn['burger-menu__bot']}>
                        <BurgerSocial />
                    </div>
                </div>
            </div>
        </div>
    );
}
