import BasicImage from 'core/BasicImage';
import React from 'react';

function Logo() {
    return (
        <BasicImage
            src="/images/svg/header/logo.svg"
            width={0}
            height={0}
            sizes="100vw"
            style={{ width: '100%', height: 'auto' }} // optional
            alt="Вебпрактик"
        />
    );
}

export default Logo;
