import clsx from 'clsx';
import { usePathname } from 'next/navigation';

import cn from './style.module.sass';

type NavigationActiveProps = {
    linkName: string;
    childLinks?: string[];
};

export default function NavigationActive({ linkName, childLinks = [] }: NavigationActiveProps) {
    const currentUrl = usePathname();
    const isActive = childLinks.includes(currentUrl);

    return <div className={clsx(cn['navigation'], isActive && cn['active'])}>{linkName}</div>;
}
