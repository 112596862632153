import { linkBot, linkTop } from '../../constants';

type MenuBurgerProps = {
    closeMenu: () => void;
};

import clsx from 'clsx';
import BasicLink from 'core/BasicLink';

import cn from './style.module.sass';

export default function MenuBurger({ closeMenu }: MenuBurgerProps) {
    const onNavigate = () => {
        closeMenu();
    };

    return (
        <div className={cn['menu-burger']}>
            <div className={cn['menu-burger__top']}>
                {linkTop.map(({ link, linkName }) => {
                    return (
                        <div key={linkName} className={cn['menu-burger__top-item']}>
                            <BasicLink
                                href={link}
                                className={cn['menu-burger__top-link']}
                                activeClassName={cn.active}
                                onClick={onNavigate}
                            >
                                {linkName}
                            </BasicLink>
                        </div>
                    );
                })}
            </div>

            <div className={cn['menu-burger__bot']}>
                {linkBot.map(({ link, linkName, isBlank }) => {
                    return (
                        <div key={linkName} className={cn['menu-burger__bot-item']}>
                            {isBlank ? (
                                <BasicLink
                                    href={link}
                                    target="_blank"
                                    className={clsx(
                                        // eslint-disable-next-line sonarjs/no-duplicate-string
                                        cn['menu-burger__bot-link'],
                                        cn['menu-burger__bot-link--icon']
                                    )}
                                >
                                    {linkName}
                                </BasicLink>
                            ) : link ? (
                                <BasicLink
                                    href={link}
                                    className={cn['menu-burger__bot-link']}
                                    activeClassName={cn.active}
                                    onClick={onNavigate}
                                >
                                    {linkName}
                                </BasicLink>
                            ) : (
                                <div className={cn['menu-burger__bot-link']}>{linkName}</div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
