import BasicImage from 'core/BasicImage';
import BasicLink from 'core/BasicLink';

import cn from './style.module.sass';

export default function BurgerSocial() {
    return (
        <div className={cn['burger-social']}>
            <div className={cn['burger-social__item']}>
                <BasicLink
                    href="https://rostov.hh.ru/employer/726289"
                    className={cn['burger-social__link']}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                >
                    <BasicImage width={26} height={26} src="/images/svg/hh.svg" alt="hh.ru" />
                </BasicLink>
            </div>
            <div className={cn['burger-social__item']}>
                <BasicLink
                    href="https://vk.com/agencywebpractik"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    className={cn['burger-social__link']}
                >
                    <BasicImage width={22} height={13} src="/images/svg/vk.svg" alt="vk.com" />
                </BasicLink>
            </div>
        </div>
    );
}
