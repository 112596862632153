import clsx from 'clsx';
import BurgerMenu from 'shared/Header/BurgerMenu';

import cn from './style.module.sass';

type HeaderMobileMenuProps = {
    openMenu: boolean;
    handleCloseMenu: () => void;
    handleToggle: () => void;
};

export default function HeaderMobileMenu({
    openMenu,
    handleCloseMenu,
    handleToggle,
}: HeaderMobileMenuProps) {
    const headerBurgerGlobal = 'header-burger__global';

    return (
        <div
            className={clsx(cn['header__mobail-menu'], {
                [cn.active as string]: openMenu,
            })}
        >
            <div className={cn['burger-menu__bg']} role="presentation" onClick={handleCloseMenu} />
            <div className={cn['header__mobail-wrap']}>
                <div
                    role="presentation"
                    className={clsx(cn['header-burger'], { [cn['active']!]: openMenu })}
                    onClick={handleToggle}
                >
                    <div className={cn['header-burger__wrap']}>
                        <span
                            className={clsx(
                                cn[headerBurgerGlobal],
                                cn['header-burger__global--top']
                            )}
                        />
                        <span
                            className={clsx(
                                cn[headerBurgerGlobal],
                                cn['header-burger__global--middle']
                            )}
                        />
                        <span
                            className={clsx(
                                cn[headerBurgerGlobal],
                                cn['header-burger__global--bottom']
                            )}
                        />
                    </div>
                </div>
                <BurgerMenu closeMenu={handleCloseMenu} />
            </div>
        </div>
    );
}
