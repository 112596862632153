import { getExternalLink } from 'core/BasicImage/basic-image.util';
import { Metadata } from 'next';

import metaData from '~/lib/data/meta.json';

export const getBasicPath = (path: string) => {
    return `${process.env.NEXT_PUBLIC_FRONT_URL || ''}${path}`;
};

export const getStaticMeta = (pageName: string): Metadata => {
    const keyOfData = pageName as keyof typeof metaData;
    const baseUrl = process.env.NEXT_PUBLIC_FRONT_URL || '';
    return {
        title: metaData[keyOfData].title,
        description: metaData[keyOfData].description,
        openGraph: {
            title: metaData[keyOfData].title,
            description: metaData[keyOfData].description,
            siteName: metaData[keyOfData].title,
            type: 'website',
            url: getBasicPath(pageName),
            images: getExternalLink('/images/pic/social/webpractik.png'),
        },
        twitter: {
            card: 'summary',
            title: metaData[keyOfData].title,
            description: metaData[keyOfData].description,
            images: getExternalLink('/images/pic/social/wp.png'),
        },
        metadataBase: new URL(getBasicPath(pageName)),
        alternates: {
            canonical: `${baseUrl}${pageName}`,
        },
    };
};
